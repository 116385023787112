import React, { ReactEventHandler, useEffect, useRef, useState } from "react";
// import Placeholder from "../placeholder";
import PreactImagePropsTypes from "./types";
// import "./style.css";

export default function PreactImage({ image, alt, id, loading = "lazy", className, containerClassName }: PreactImagePropsTypes) {
  // const [isLoaded, setIsLoaded] = useState(false);
  // const imageRef = useRef<HTMLImageElement>(null!)
  // const onLoad: ReactEventHandler<HTMLImageElement> = (e) => {
  //   setIsLoaded(true);
  // }

  // useEffect(() => {
  //   if (imageRef.current.complete === true) {
  //     setIsLoaded(true);
  //   }
  // }, []);

  return (
    <picture className={`position-relative ${containerClassName}`} id={id}>
      {/* {
        isLoaded === false && (
          <Placeholder className={"preact-image__placeholder w-100 h-100"} />
        )
      } */}
      {
        image.images.sources?.map((props) => (
          <source key={`product-swiper-slide-image-item-${id}`} {...props} />
        ))
      }
      <img
        src={image?.images?.fallback?.src}
        alt={alt}
        data-type={"_image_"}
        className={className}
        loading={loading}
        decoding={"async"}
        height={image.height}
        width={image.width}
      // ref={imageRef}
      // onLoad={onLoad}
      />
    </picture>
  )
}
