import React from "react";
import PriceTagPropsTypes from "./types";

export default function PriceTag({ price, regular_price, className }: PriceTagPropsTypes) {
  const onSale = price < regular_price;

  return (
    <p className={className}>
      {
        onSale && (
          <del className="text-sm">{regular_price}DZD</del>
        )
      }
      {` `}<span>{price}DZD</span>
    </p>
  )
}
