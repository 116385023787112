import React from "react";
import ProductDetailsListProps from "./types";

/**
 * Display extra details of the product if there is any
 * @param product details such as sku, height..
 */
export default function ProductDetailsList({ product, className = '' }: ProductDetailsListProps) {
  if (!product?.sku && !product?.width && !product?.height && !product?.weight && !product?.length) {
    return null;
  }

  return (
    <ul className={`list-unstyled small d-inline-block mt-4 ${className}`}>
      {
        !!product?.sku && (
          <li className="px-3 py-2 mb-1 bg-white">
            <strong className="text-uppercase">SKU:</strong>{` `}
            <span className="ms-2 text-muted">{product.sku}</span>
          </li>
        )
      }
      {
        (!!product?.width || !!product?.height) && (
          <li className="px-3 py-2 mb-1 bg-white text-muted">
            <strong className="text-uppercase text-dark">الأبعاد(cm):</strong>{` `}
            <span>{product?.width || ""}</span>{` `}
            &times;{` `}
            <span>{product?.height || ""}</span>
          </li>
        )
      }
      {
        !!product?.length && (
          <li className="px-3 py-2 mb-1 bg-white text-muted">
            <strong className="text-uppercase text-dark">الطول(cm):</strong>{` `}
            <span className="reset-anchor ms-2">{product?.length || ""}</span>
          </li>
        )
      }
      {
        !!product?.weight && (
          <li className="px-3 py-2 mb-1 bg-white text-muted">
            <strong className="text-uppercase text-dark">الوزن(kg):</strong>
            <span className="reset-anchor ms-2">{product?.weight || ""}</span>
          </li>
        )
      }
    </ul>
  );
}
