import { graphql, navigate, PageProps, Script } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { constructCartDataByProduct } from "../../../helpers/cart";
import { objectifyFormData } from "../../../helpers/form";
import httpRequest from "../../../helpers/http";
import { generateProductImageSet, getImageLink } from "../../../helpers/images";
import { globalReducer } from "../../../helpers/reducers";
import CartStorage from "../../../helpers/storage";
import { validateOrderLineItem } from "../../../helpers/validator/cart";
import { AttributesPropsTypes, ImageType } from "../../../types/global";
import Layout from "../../layout";
import SEO from "../../seo";
import { LineItemType, ProductPageDataType } from "./types";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Thumbs } from "swiper";
import Alert from "../../alert";
import { scrollIntoView } from "../../../helpers/ui";
import ProductDetailsList from "../../product-details-list";
import loadable from '@loadable/component'
import Placeholder from "../../placeholder";
import PreactImage from "../../preact-image";
import { addToCart, customEvent, sendMetaEventsToServerByStore } from "../../../helpers/analytics/facebook-pixel";
import PriceTag from "../../price";
import { extractStoreKeyFromShippingUrl } from "../../../helpers/data/tools";
import useProductAvailability from "../../../hooks/use-product-availability";

import "glightbox/dist/css/glightbox.min.css";
import 'swiper/css';
import "swiper/css/bundle";
import "./style.css";
import "../store-page/ql-text-editor-style.css";

const initState = {
  loading: false,
  data: null,
  errors: null,
  message: null,
};
const loadableInitState = {
  ...initState,
  data: {
    ProductsRows: null,
    Modal: null,
  }
};

export default function Product({ data: { product, storeDetails, allWcProducts: { edges: products } } }: PageProps<ProductPageDataType>) {
  const [state, dispatch] = useReducer(globalReducer, initState);
  const [isNotAvailableInStock] = useProductAvailability({
    dispatch,
    product_id: product.wordpress_id,
    product_stock_url: storeDetails.product_stock_url,
    facebookPixelId: storeDetails.store_primary_settings.store_analytics?.facebook_pixel,
  });
  const [loadableState, dispatchLoadable] = useReducer(globalReducer, loadableInitState);
  const quantityRef = useRef<HTMLInputElement>(null!);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const fbEventID = useRef(`event.id.${Math.random() * 1000000}-${Date.now()}`).current;
  const facebookPixelId = storeDetails.store_primary_settings.store_analytics.facebook_pixel;
  const alertMessage = state.message || state.errors?.global;
  const alertVariant = state.message ? "success" : "danger";
  const swiperThumbs = {
    swiper: thumbsSwiper
  };
  const productDates = {
    first_publication_date: product.date_created.date,
    last_publication_date: product.date_modified.date,
  };
  const storeKey = extractStoreKeyFromShippingUrl(storeDetails.store_shipping);
  const onSubmit = (mode: "buy-now" | "add-to-cart") => async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (isNotAvailableInStock) return;

    dispatch({
      type: "LOADING",
      payload: true
    });
    !!facebookPixelId && customEvent("StartAddToCart", {
      content_name: product.name,
      price: product?.price,
      regular_price: product?.regular_price,
      contents: [{ id: product.wordpress_id, quantity: 1, product_build_id: product.id }],
      timestamp: Date.now()
    });

    const form = mode === "add-to-cart"
      ? event.currentTarget
      : document.querySelector(".js-buy-now-form") as HTMLFormElement;
    const formData = new FormData(form);
    const orderData = objectifyFormData(formData);
    const lineItem = constructCartDataByProduct(orderData);
    const errors = validateOrderLineItem(lineItem, product?.attributes as AttributesPropsTypes[]);
    const cart = new CartStorage();
    const cartItems = await cart.getAllItems() as any[];
    /**
     * Verify if the given product is already in the cart
     * then add that quantity to the new one and check its availability
     */
    const existingProductInCart = cartItems?.find((item: any) => `${item.product_id}` === `${orderData.product_id}`);
    let productQuantityToCheck = Number(orderData.quantity);

    if (!!existingProductInCart) {
      productQuantityToCheck += Number(existingProductInCart.quantity);
    }

    if (errors !== false) {
      await dispatch({
        type: "ERROR",
        payload: errors
      });
      const elementClassName = errors?.global
        ? ".alert"
        : ".invalid-feedback";
      scrollIntoView(document.querySelector(elementClassName));
      !!facebookPixelId && customEvent("ValidationErrorInAddToCart", {
        contents: errors
      });
      return false;
    }

    const response = await httpRequest({
      url: `${storeDetails.product_stock_url}/${orderData.product_id}/availability?quantity=${productQuantityToCheck}`,
      requestConfig: {
        method: "GET",
      }
    });

    if (response?.code !== "success") {
      await dispatch({
        type: "ERROR",
        payload: {
          global: "نفد هذا المنتج من المخزن"
        } || response.errors
      });
      const elementClassName = response?.errors?.global
        ? ".alert"
        : ".invalid-feedback";
      scrollIntoView(document.querySelector(elementClassName));
      !!facebookPixelId && customEvent("ServerErrorInAddToCart", {
        contents: response.errors
      });
      !!facebookPixelId && customEvent("ProductStockCheck", {
        status: "outofstock"
      });
      return;
    } else {
      const productDetails = {
        id: String(product.wordpress_id),
        slug: product.slug,
        name: product.name,
        regular_price: product.price || product.regular_price,
        thumbnail: product.thumbnail,
      }
      await Promise.all([
        cart.addItem(lineItem as LineItemType),
        cart.pushItemDetails(productDetails)
      ]);
      mode === "add-to-cart" && dispatch({
        type: "SUCCESS",
        payload: "Ajouter au panier avec succes"
      });
      !!facebookPixelId && customEvent("ProductStockCheck", {
        status: "instock"
      });
    }
    // send pixel event
    !!facebookPixelId && addToCart(product, lineItem?.quantity || 1, {
      pixelId: facebookPixelId,
      storeKey,
      sourceUrl: location.href
    });
    // scroll to the alert element
    if (mode === "add-to-cart") {
      scrollIntoView(document.querySelector(".alert"));
    }
    // navigate to checkout if a customer presson "buy now" button
    if (mode === "buy-now") {
      !!facebookPixelId && customEvent("StartNavigatingToCheckout", {
        timestamp: Date.now()
      });
      await navigate("/cart/checkout");
    }
  }
  const onEditQuantity = (increase = true) => () => {
    if (quantityRef.current !== null) {
      const { value = "1" } = quantityRef.current;
      let quantity = parseInt(value, 10);
      quantity = increase === true
        ? quantity + 1
        : quantity === 1
          ? 1
          : quantity - 1;
      quantityRef.current.value = `${quantity}`;
      !!facebookPixelId && customEvent("EditProductQuantity", {
        contents: [{ id: product.wordpress_id, quantity: quantity }],
        timestamp: Date.now()
      });
    }
  }

  useEffect(() => {
    let recommendationTitle: HTMLElement | null;
    let observer: IntersectionObserver;

    if (typeof window !== 'undefined') {
      // @ts-ignore
      import("glightbox/dist/js/glightbox.min.js")
        .then(({ default: GLightbox }) => {
          GLightbox({
            touchNavigation: true,
          });
        });
      // load components
      recommendationTitle = document.querySelector('.js-recommanded-products');
      let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
      const observerCallback: IntersectionObserverCallback = (entries, obsrvr) => {
        entries.forEach((entry) => {
          const { ProductsRows, Modal } = loadableState?.data;

          if (entry.isIntersecting === true && ProductsRows === null && Modal === null) {
            const placeholderStyle = {
              height: 250
            };
            const _productRows = loadable(() => import('../../products-rows'), {
              fallback: <Placeholder style={placeholderStyle} />
            });
            const _modal = loadable(() => import('../../modal'));
            dispatchLoadable({
              type: "SET_FIELDS",
              payload: {
                ProductsRows: _productRows,
                Modal: _modal,
              }
            });
            observer?.unobserve(recommendationTitle as HTMLElement);
            !!facebookPixelId && customEvent("LoadRecommendedProducts");
          }
        })
      }
      observer = new IntersectionObserver(observerCallback, options);
      observer.observe(recommendationTitle as HTMLElement);
      !!facebookPixelId && sendMetaEventsToServerByStore(
        `${storeKey}`,
        {
          pixelId: `${facebookPixelId}`,
          eventData: {
            event_id: fbEventID,
            event_name: "ViewContent",
            action_source: "website",
            event_source_url: location.href
          },
          customData: {
            content_name: `${product.name}`,
            content_ids: [`${product.wordpress_id}`],
            content_type: "product",
            contents: [{ id: `${product.wordpress_id}`, quantity: 1 }],
            value: Number(product.price),
            currency: "DZD",
          }
        }
      );
    }

    return () => {
      recommendationTitle !== null && observer?.unobserve(recommendationTitle);
    }
  }, []);

  return (
    <Layout className="page-holder bg-light">
      <div id={`product-${product.id}`}>
        <SEO
          banner={String(product.images?.[0].src)}
          pathname={`/product/${product.slug}/`}
          description={product?.short_description || product?.name || ""}
          title={product.name}
          node={productDates}
          product={product}
          article={false}
        />

        <section className="py-3 py-md-4 py-lg-5">
          <div className="container">
            {/* <!-- MODAL ---> */}
            {
              loadableState.data.Modal !== null && (
                <loadableState.data.Modal />
              )
            }
            {/* PRODUCT FORM */}
            <div className="row mb-5">
              <div className="col-lg-6">
                {/* <!-- PRODUCT SLIDER--> */}
                <div className="row m-sm-0">
                  {/* <!-- THUMBNAIL SLIDER--> */}
                  <div className="col-sm-2 p-sm-0 order-2 order-sm-1 mt-2 mt-sm-0 px-xl-2">
                    <Swiper
                      direction="horizontal"
                      className={"product-slider-thumbs h-100"}
                      slidesPerView={5}
                      spaceBetween={10}
                      modules={[Thumbs]}
                      watchSlidesProgress
                      // @ts-expect-error
                      onSwiper={setThumbsSwiper}
                      breakpoints={{
                        576: {
                          direction: "vertical",
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                      }}
                    >
                      {
                        product?.images?.map((productImage: ImageType | null) => {
                          // avoid error if image is null
                          // @ts-expect-error
                          if (productImage === null || !!productImage === false) {
                            return null;
                          }

                          const { height, width, id, src } = productImage;
                          const images = {
                            fallback: {
                              src: src,
                            },
                            sources: [
                              {
                                media: "",
                                type: "image/webp",
                                srcSet: generateProductImageSet(src, ["992"], [{ height: 150 }]).srcSet,
                              },
                            ]
                          };
                          const imageWidth = 80;
                          const imageHeight = height * imageWidth / width;
                          const image: IGatsbyImageData = {
                            height: imageHeight,
                            width: imageWidth,
                            backgroundColor: "#12206755",
                            layout: "fixed",
                            images
                          };

                          return (
                            <SwiperSlide
                              key={`product-swiper-slide-image-${id}`}
                              className="h-auto swiper-thumb-item product-slider-thumbs__image mb-3 overflow-hidden"
                            >
                              <PreactImage
                                image={image}
                                alt={product.name}
                                id={`slider-thumb-${id}`}
                                className={"w-auto h-100 mw-100 object-fit_contain"}
                                containerClassName={"d-flex justify-content-center align-items-center mw-100 mh-100"}
                              />
                            </SwiperSlide>
                          );
                        })
                      }
                    </Swiper>
                  </div>
                  {/* <!-- PRODUCT IMAGES SLIDER--> */}
                  <div className="col-sm-10 order-1 order-sm-2">
                    <Swiper
                      className="product-slider h-100"
                      slidesPerView={1}
                      spaceBetween={0}
                      modules={[Thumbs]}
                      thumbs={swiperThumbs}
                    >
                      {
                        product?.images?.map((productImage: ImageType | null, index) => {
                          // avoid error if image is null
                          // @ts-expect-error
                          if (productImage === null || !!productImage === false) {
                            return null;
                          }

                          const { height, width, id, src } = productImage;
                          const srcSet = getImageLink(src);
                          const images: IGatsbyImageData["images"] = {
                            fallback: {
                              src
                            },
                            sources: [
                              {
                                media: "",
                                type: "image/webp",
                                srcSet: generateProductImageSet(src, ["992"], [{ height: 550 }]).srcSet,
                              },
                            ]
                          };
                          const image: IGatsbyImageData = {
                            height,
                            width,
                            backgroundColor: "#12206755",
                            layout: "fullWidth",
                            images
                          };

                          return (
                            <SwiperSlide
                              key={`product-swiper-slide-main-image-${id}`}
                              className={"h-auto"}
                            >
                              <a
                                className="glightbox product-view d-block h-100"
                                href={srcSet}
                                data-gallery="gallery2"
                                data-glightbox={`Product image item ${index}`}
                              >
                                <PreactImage
                                  image={image}
                                  alt={product.name}
                                  loading={index === 0 ? "eager" : "lazy"}
                                  className={"img-fluid"}
                                  containerClassName={"product-slider-main__image d-flex justify-content-center align-items-center w-100 h-100"}
                                  id={`slider-image-${id}`}
                                />
                              </a>
                            </SwiperSlide>
                          );
                        })
                      }
                    </Swiper>
                  </div>
                </div>
              </div>
              {/* <!-- PRODUCT DETAILS--> */}
              <form
                onSubmit={onSubmit("add-to-cart")}
                className="col-lg-6 js-buy-now-form"
                dir={"rtl"}
              >
                <input name="product_id" value={product.wordpress_id} type="hidden" />
                <h1 className={"mt-0 mt-sm-3 mt-lg-0"}>{product.name}</h1>
                <PriceTag
                  className="text-muted lead"
                  regular_price={Number(product.regular_price || 0)}
                  price={Number(product.price || 0)}
                />
                <Alert variant={alertVariant}>
                  {alertMessage}
                </Alert>
                <div
                  className={"text-sm mb-4 text-truncated overflow-hidden"}
                  dangerouslySetInnerHTML={{ __html: product?.short_description || product?.description || "" }}
                />
                {
                  // @ts-ignore
                  product?.attributes?.length > 0 && (

                    <div className="row align-items-stretch mb-4">
                      <h4>خيارات</h4>
                      {
                        product.attributes?.map((attribute: any, index: number) => {
                          if (attribute.visible === true) {
                            return (
                              <div
                                key={`product-attribute-${attribute?.id}`}
                                // @ts-ignore
                                className={(index + 1) < product?.attributes?.length ? "mb-3" : ""}
                              >
                                <h6 className="mb-2">
                                  <strong className="text-capitalize">
                                    {attribute?.name}:
                                  </strong>
                                </h6>
                                <div className="d-flex flex-wrap gap-3">
                                  {
                                    attribute.options.map((option: any) => (
                                      <label key={`attribute-value-${option}`}>
                                        <input
                                          key={option}
                                          type={"radio"}
                                          name={`options[${attribute?.name}]`}
                                          value={option}
                                          className={"custom__radio-input d-none"}
                                        />
                                        <span className={"custom__radio-text py-2 px-3"}>
                                          {option}
                                        </span>
                                      </label>
                                    ))
                                  }
                                </div>
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })
                      }
                      {/* <span style={{ color: "#9a1111" }}>{state.errors?.options}</span> */}
                      {
                        !!state.errors?.options && (
                          <div className="invalid-feedback d-block mt-2">
                            {state.errors?.options}
                          </div>
                        )
                      }
                    </div>
                  )
                }
                <div className="row align-items-stretch form-action__block overflow-hidden">
                  <div className="col-sm-5 pr-sm-0 form-action__input-container">
                    <div className="quantity-input__container border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white">
                      <label htmlFor="quantity" className="small text-uppercase text-gray mr-4 no-select">الكمية</label>
                      <div className="quantity">
                        <button
                          className="dec-btn p-0"
                          onClick={onEditQuantity(false)}
                          type={"button"}
                          aria-label={"decrementer"}
                        >
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
                          </svg>
                        </button>
                        <input
                          className="form-control border-0 shadow-0 p-0"
                          type="text"
                          value={"1"}
                          name="quantity"
                          id="quantity"
                          ref={quantityRef}
                        />
                        <button
                          className="inc-btn p-0"
                          onClick={onEditQuantity()}
                          type={"button"}
                          aria-label={"incrementer"}
                        >
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 192 512" height="1rem" width="1rem" xmlns="http://www.w3.org/2000/svg">
                            <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7 pl-sm-0 d-flex flex-column flex-lg-row gap-3 form-action__btns-container">
                    <button
                      className="btn btn-dark btn-sm btn-block h-100 d-flex w-100 align-items-center justify-content-center text-truncate"
                      type="button"
                      disabled={isNotAvailableInStock || state.loading}
                      onClick={onSubmit("buy-now") as any}
                    >
                      <span>
                        {(isNotAvailableInStock) ? "نفاذ" : "اشتري"}{` `}
                        <span className="sm-hide">{(isNotAvailableInStock) ? "المخزون" : "الآن"}</span>
                      </span>
                    </button>
                    <button
                      className="btn btn-outline-dark btn-sm btn-block h-100 d-flex w-100 align-items-center justify-content-center"
                      type="submit"
                      disabled={isNotAvailableInStock || state.loading}
                      aria-label="أضف إلى السلة"
                    >
                      <span className="sm-hide">
                        {
                          (isNotAvailableInStock)
                            ? "نفاذ المخزون"
                            : "أضف إلى السلة"
                        }
                      </span>
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 576 512"
                        height="1rem"
                        width="1rem"
                        xmlns="http://www.w3.org/2000/svg"
                        className="d-none sm-show"
                      >
                        <path d="M528.12 301.319l47.273-208C578.806 78.301 567.391 64 551.99 64H159.208l-9.166-44.81C147.758 8.021 137.93 0 126.529 0H24C10.745 0 0 10.745 0 24v16c0 13.255 10.745 24 24 24h69.883l70.248 343.435C147.325 417.1 136 435.222 136 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-15.674-6.447-29.835-16.824-40h209.647C430.447 426.165 424 440.326 424 456c0 30.928 25.072 56 56 56s56-25.072 56-56c0-22.172-12.888-41.332-31.579-50.405l5.517-24.276c3.413-15.018-8.002-29.319-23.403-29.319H218.117l-6.545-32h293.145c11.206 0 20.92-7.754 23.403-18.681z" />
                      </svg>
                    </button>
                  </div>
                  {
                    !!state.errors?.quantity && (
                      <div className="invalid-feedback d-block mt-2">
                        {state.errors?.quantity}
                      </div>
                    )
                  }
                </div>
                <ProductDetailsList
                  product={product}
                  className={"row"}
                />
              </form>
            </div>
            {/* <!-- DETAILS TABS--> */}
            <ul className="nav nav-tabs border-0" id="myTab" aria-owns="description-tab-1" role="tablist"
              dir="rtl" style={{ paddingRight: 0 }}
            >
              <li className="nav-item">
                <a className="nav-link text-uppercase active" id="description-tab-1" data-bs-toggle="tab" href="#description" role="tab" aria-controls="description" aria-selected="true">الوصف</a>
              </li>
            </ul>
            <div className="tab-content mb-5" id="myTabContent">
              <div className="tab-pane fade show active" id="description" role="tabpanel" aria-labelledby="description-tab">
                <div className="p-4 p-lg-5 bg-white" dir="rtl">
                  <strong className="h6 text-uppercase">وصف المنتوج</strong>
                  <div
                    className="text-muted text-sm mb-0"
                    dangerouslySetInnerHTML={{ __html: String(product.description || "") }}
                  />
                </div>
              </div>
            </div>
            {/* <!-- RELATED PRODUCTS--> */}
            <h2 className="h5 text-uppercase mb-4 js-recommanded-products" dir={"rtl"}>التوصيات</h2>
            {/* <!-- PRODUCT--> */}
            {
              loadableState?.data?.ProductsRows !== null && (
                <loadableState.data.ProductsRows
                  products={products}
                  columnClassName={"col-lg-3 col-sm-6"}
                />
              )
            }
          </div>
        </section>
      </div>
      {
        !!facebookPixelId && (
          <Script strategy="idle">{`
            fbq('track', 'ViewContent', {
              "content_name": "${product.name}",
              "content_ids": "['${product.wordpress_id}']",
              "content_type": "product",
              "contents":${JSON.stringify([{ id: `${product.wordpress_id}`, quantity: 1 }])},
              "value": ${product.price},
              "currency": "DZD",
              "source": "zzenz",
              "version": "1.0.0",
              "timestamp": "${Date.now()}"
            }, { "eventID": "${fbEventID}" });
          `}</Script>
        )
      }
    </Layout>
  );
}

export const pageQuery = graphql`
  query ($id: String!, $productId: Int!, $categories: [Int]) {
    allWcProducts(
      filter: {
        category_ids: {
          in: $categories
        }
        wordpress_id: {
          ne: $productId
        }
      }
      limit: 4
    ) {
      edges {
        node {
          name
          slug
          id
          regular_price
          price
          wordpress_id
          description
          images {
            id
            src
            height
            width
          }
        }
      }
    }
    product: wcProducts (id: {eq: $id }) {
      id
      wordpress_id
      name
      slug
      regular_price
      price
      description
      short_description
      sku
      length
      height
      width
      weight
      images {
        id
        src
        height
        width
      }
      thumbnail {
        id
        src
        height
        width
      }
      attributes {
        id
        name
        options
        visible
      }
      date_created {
        date
        timezone
      }
      date_modified{
        date
        timezone_type
      }
      stock_quantity
      stock_status
      manage_stock
    }
    storeDetails: zzStoreWebsiteDetails {
      product_stock_url
      store_shipping
      store_primary_settings {
        store_analytics {
          facebook_pixel
        }
      }
    }
  }
`;