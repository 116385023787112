import React from "react";
import "./style.css";

export default function Placeholder({ style, className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={`placeholder-container ${className}`}
      style={style}
    />
  );
}
